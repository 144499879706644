/*---------------------
    Minicart Style  
---------------------*/
.rbt-minicart-wrapper {
    padding: 0;
    margin: 0;
    list-style: none;
    .minicart-item {
        display: flex;
        @extend %transition;
        position: relative;
        margin: 0;
        margin-bottom: 20px;
        .close-btn {
            position: absolute;
            right: 0;
            top: 0;
            @media #{$large-mobile} {
                position: static;
            }
            button {
                font-size: 14px;
                top: 10px;
                position: relative;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }

        & + .minicart-item {
            border-top: 1px dashed var(--color-border);
            padding-top: 20px;
        }
    }
    .thumbnail {
        flex-shrink: 0;
        a {
            display: block;
            img {
                width: 80px;
                height: 80px;
                border-radius: var(--radius);
            }
        }
    }
    .product-content {
        flex-grow: 1;
        padding-left: 20px;
        .title {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .quantity {
            font-size: 14px;
            color: var(--color-body);
            display: block;
            .price {

            }
        }
    }
}

.rbt-cart-side-menu {
    .inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .side-nav {
        max-height: 50vh;
        overflow-y: auto;
        flex-grow: 1;
    }
    .rbt-cart-subttotal {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
      
        p {
            margin-bottom: 0;
            color: var(--color-heading);
            font-weight: 700;
            font-size: 18px;
        }
    }
}



