/*----------------------------
    Service Card Styles  
------------------------------*/
.rbt-service {
    &.rbt-service-1 {
        border-radius: 6px;
        .inner {
            padding: 30px;
            @media #{$sm-layout} {
                padding: 22px 18px;
            }
            .icon {
                position: relative;
                display: inline-block;
                margin-bottom: 24px;
                @media #{$sm-layout} {
                    margin-bottom: 15px;
                }
                img {
                    max-width: 55px;
                }
            }

            .content {
                .title {
                    margin-bottom: 16px;
                    @media #{$sm-layout} {
                        margin-bottom: 9px;
                    }
                }
                p {
                    @media #{$sm-layout} {
                        margin-bottom: 12px;
                    }
                }
            }
        }
        .rbt-flipbox-back {
            &.inner {
                p {
                    color: var(--color-white);
                }
            }
        }
    }
    &.rbt-service-2 {
        padding: 30px;
        background: var(--color-white);
        box-shadow: var(--shadow-1);
        border-radius: var(--radius);
        @media #{$md-layout} {
            padding: 30px 20px;
        }
        @media #{$sm-layout} {
            padding: 30px 20px;
        }
        .inner {
            display: flex;
            align-items: center;
            @media #{$md-layout} {
                flex-direction: column-reverse;
            }
            @media #{$sm-layout} {
                flex-direction: column-reverse;
            }
            .thumbnail {
                flex-basis: 50%;
                @media #{$sm-layout} {
                    flex-basis: 100%;
                    margin-bottom: 10px;
                }
                img{
                    width: 100%;
                }
            }
            .content {
                flex-basis: 50%;
                @media #{$sm-layout} {
                    flex-basis: 100%;
                }
                .title {
                    margin-bottom: 16px;
                    @media #{$lg-layout} {
                        font-size: 22px;
                    }
                    @media #{$md-layout} {
                        font-size: 22px;
                    }
                    @media #{$sm-layout} {
                        font-size: 22px;
                    }
                }
                p {
                    font-size: 16px;
                    margin-bottom: 12px;
                }
            }
        }
        
        &.bg-gray-ligt {
            background: var(--color-gray-light);
            box-shadow: none;
        }
        &.bg-no-shadow {
            background: var(--color-white);
            box-shadow: none;
        }

        &.variation-2 {
            .inner {
                .content {
                    flex-basis: 65%;
                    .title {
                        margin-bottom: 8px;
                    }
                }
                .thumbnail {
                    flex-basis: 35%;
                }
            }
        }
    }
}


.rbt-flipbox {
    position: relative;
    perspective: 1200px;
    overflow: hidden;
    border-radius: 6px;
    height: 100%;

    .rbt-flipbox-wrap {
        position: relative;
        transition-property: box-shadow, transform;
        transition-duration: .6s;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transform-style: preserve-3d;
        height: 100%;

        .rbt-flipbox-face {
            transform-style: preserve-3d;
            backface-visibility: hidden;
            backface-visibility: hidden;
            transition: .6s;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1);
            transform-style: preserve-3d;
        }

        .rbt-flipbox-front {
            .content { 
                > a {
                    margin-top: 50px;
                    @media #{$md-layout} {
                        margin-top: 20px;
                    }
                    @media #{$sm-layout} {
                        margin-top: 10px;
                    }
                }
            }
        }

        .rbt-flipbox-back {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(180deg) translateZ(1px);
            background: linear-gradient(1turn,#e9dcfa,#d05aff);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: space-between;
            text-align: center;
            border-radius: 6px;
            text-align: left;
        }
    }

    &:hover {
        .rbt-flipbox-wrap {
            transform: rotateY(180deg);
            transform-origin: 50% 50% !important;
            .rbt-flipbox-face {
                backface-visibility: inherit;
                backface-visibility: inherit;
            }
        }
    }

    &.variation-2 {
        .front-thumb,
        .back-thumb {
            width: 100%;
            img {
                border-radius: 10px;
                width: 100%;
                object-fit: cover;
                max-height: 150px;
                margin-bottom: 25px;
            }
        }
    }
}


/*-------------------------------
    Service Style Five  
---------------------------------*/
.service-card-5 {
    .inner {
        border-radius: 5px;
        padding: 20px;
        transition: 0.4s;
        display: flex;
        align-items: center;
        transition: .35s cubic-bezier(.23,1,.32,1),opacity .35s .1s cubic-bezier(.23,1,.32,1);
        position: relative;
        z-index: 1;
        box-shadow: var(--shadow-9);
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all .3s ease;
            background: var(--color-white);
            border-radius: 5px;
        }
        .icon {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            border-radius: 2px;
            transition: 0.4s;
            justify-content: center;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 15px;
            }
        }
        .content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                transition: 0.4s;
                @media #{$lg-layout} {
                    font-size: 15px;
                    line-height: 28px;
                }
                @media #{$md-layout} {
                    font-size: 16px;
                }
                @media #{$sm-layout} {
                    font-size: 16px;
                    text-align: center;
                }
            }
            .description {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                transition: 0.4s;
            }
        }
    }
    &:hover {
        .inner {
            &::before {
                background: var(--color-white);
                transform: scale(1.1);
                transition: transform .35s cubic-bezier(.23,1,.32,1),opacity .1s 0s cubic-bezier(.23,1,.32,1);
            }
        }
    }

    &.variation-2 {
        .inner {
            box-shadow: none;
            flex-direction: column;
            padding: 0;
            &::before {
                display: none;
            }
            .icon {
                margin-right: 0;
                margin-bottom: 15px;
                background: var(--pink-opacity);
                width: 100px;
                height: 100px;
                border-radius: 100%;
                overflow: hidden;
                position: relative;
                
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 2;
                    padding: 4px;
                    &::before {
                        content: "";
                        z-index: -1;
                        top: 0;
                        left: 0;
                        position: absolute;
                        background: linear-gradient(90deg, #CFA2E8, #637FEA);
                        width: 100%;
                        height: 100%;
                    }
                }
                
                img {
                    max-height: 100%;
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                    border-radius: 100%;
                    @extend %transition;
                }
            }
            &:hover {
                .icon {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }


}

/*-------------------------------
    Service Style Five  
---------------------------------*/
.service-card-6 {
    height: 100%;
    .inner {
        border-radius: 5px;
        padding: 45px 35px;
        position: relative;
        z-index: 1;
        border: 1px solid var(--color-border);
        height: 100%;
        @media #{$sm-layout} {
            padding: 30px 20px;
        }
        .icon {
            position: relative;
            display: inline-block;
            margin-bottom: 15px;
            img {
                max-height: 60px;
            }
            
            .opacity_image {
                position: absolute;
                transform: translateX(-50%) scale(0.8);
                opacity: 0.8;
                z-index: -1;
                -webkit-filter: blur(15px);
                filter: blur(15px);
                left: 50%;
                top: 30%;
            }
        }
        .content {
            padding-top: 10px;
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 8px;
                transition: 0.4s;
                @media #{$lg-layout} {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
            .description {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
            }
        }

        .number-text {
            font-size: 100px;
            font-weight: 800;
            opacity: 0.05;
            display: inline-block;
            line-height: 70px;
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: -1;
        }
    }
    &:hover {
        .inner {
            &::before {
                background: var(--color-gray-light);
                transform: scale(1.1);
                transition: transform .35s cubic-bezier(.23,1,.32,1),opacity .1s 0s cubic-bezier(.23,1,.32,1);
            }
        }
    }

   

    &.bg-color {
        .inner {
            border-color: transparent;
        }
    }
}
