
/*------------------------- 
Course Sidebar 
-------------------------*/ 
.course-sidebar-top {
    margin-top: -500px;
    @media #{$md-layout} {
        margin-top: 0;
    }
    @media #{$sm-layout} {
        margin-top: 0;
    }
}



.course-sidebar {
    .subtitle {
        font-size: 14px;
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center
    }

    .social-share-wrapper {
        background: #f5f5fa;
        margin: 0 -27px -27px;
        padding: 30px;
        border-radius: 0 0 6px 6px;
        @media #{$lg-layout} {
            margin: 0 -10px -10px;
            padding: 20px;
            border-radius: 6px;
        }
        @media #{$md-layout} {
            margin: 0 -10px -10px;
            padding: 20px;
            border-radius: 6px;
        }
        @media #{$sm-layout} {
            margin: 0 -10px -10px;
            padding: 20px;
            border-radius: 6px;
        }
        .rbt-post-share {
            span {
                font-size: 16px;
            }
        }
    }

    .contact-with-us {
        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}

/* Course Video Wrapper  */
.video-popup-with-text {
    &.video-popup-wrapper {
        position: relative;
        display: block;
        z-index: 2;
        .play-view-text {
            position: absolute;
            bottom: 20px;
            text-align: center;
            width: 100%;
            z-index: 2;
        }
    
        .position-to-top {
            z-index: 2;
        }
    
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: "";
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
            transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
            cursor: pointer;
            border-radius: 6px;
        }
    }
}

.rbt-widget-details,
.rbt-default-sidebar-wrapper {
    .rbt-course-details-list-wrapper,
    .rbt-default-sidebar-list {
        @extend %liststyle;
        li {
            display: flex;
            justify-content: space-between;
            i {
                color: var(--color-body);
                margin-right: 10px;
                font-size: 16px;
            }
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                &.rbt-feature-value {
                    font-size: 12px;
                }
            }
            a {
                color: var(--color-body);
                transition: 0.4s;
                &:hover {
                    color: var(--color-primary);
                }
            }
            & + li {
                padding-top: 10px;
                margin-top: 10px;
                border-top: 1px solid var(--color-border);
            }
        }
    }
}
