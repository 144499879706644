/*---------------------------
    Header Common Styles  
----------------------------*/
.logo {
    a {
        display: block;

        img {
            max-height: 50px;
            object-fit: cover;
        }
    }
}


.rbt-header-top {
    position: relative;
    z-index: 9999;
}

.rbt-header-middle {
    position: relative;
    z-index: 999;
}


/*-----------------------------
    Header Space Between  
------------------------------*/
.header-space-betwween {
    padding-left: 150px;
    padding-right: 150px;
    @media #{$extra-device} {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$laptop-device} {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media #{$lg-layout} {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$md-layout} {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$sm-layout} {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$large-mobile} {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

/*----------------------------
    Header Top Information 
-----------------------------*/ 
.rbt-information-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    list-style: none;
    margin: -2px -7px;
    flex-wrap: wrap;
    li {
        margin: 2px 7px;
        color: var(--color-body);
        a {
            padding: 0;
            font-size: 14px;
            line-height: 22px;
            color: var(--color-body);
            display: flex;
            align-items: center;
            transition: 0.4s;
            font-weight: 400;
            i {
                margin-right: 10px;
                font-size: 14px;
                margin-top: 2px;
            }
            span {
                margin-left: 4px;
            }
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}



/*--------------------------
    Dropdown Menu  
----------------------------*/
ul {
    &.rbt-dropdown-menu {
        @extend %liststyle;
        li {
            margin: 0;
            list-style: none;
            a {
                @extend %transition-4;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                color: var(--color-body);
                .left-image {
                    width: 20px;
                    height: auto;
                    margin-right: 5px;
                }
                span {
                    display: inline-block;
                }
                .right-icon {
                    @extend %transition-4;
                    display: inline-block;
                    font-size: 14px;
                    @media #{$large-mobile} {
                        font-size: 12px;
                    }
                }
            }
            &.has-child-menu {
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
            }

            .sub-menu {
                border-top: 1px solid #e6e3f14f;
                background: #fff;
                box-shadow: var(--shadow-1);
                min-width: 170px;
                padding: 12px 0;
                margin: 0;
                list-style-type: none;
                z-index: 10;
                text-align: start;
                border-radius: var(--radius);
                right: 0;
                @extend %rbt-dropdown-type-1;

                &.hover-reverse {
                    left: auto;
                    right: 0;
                }

                li {
                    a {
                        margin: 2px 10px;
                        padding: 4px 12px;
                        background: var(--color-white);
                        border-radius: var(--radius);
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 400;
                        @extend %transition-4;
                        &:hover {
                            color: var(--color-primary);
                            background: var(--black-opacity);
                        }
                    }
                }
            }

            &:hover {
                > a {
                    .right-icon {
                        transform: rotate(180deg);
                        margin-top: 2px;
                    }
                }
                .sub-menu {
                    @extend %rbt-hover-dropdown-type-1;
                   
                }
            }
        }

        &.currency-menu {
            li {
                .sub-menu {
                    min-width: 100px;
                }
            }
        }
    }
}


/*--------------------------
    Header Section
----------------------------*/

.rbt-header-sec {
    display: flex;

    @media #{$lgtolp-device} {
        height: 100%;
    }

    @media #{$lg-layout} {
        height: 100%;
    }

    .rbt-header-sec-col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        .rbt-header-content {
            display: flex;
            align-items: center;
            margin: 0 -10px;
            @media #{$lg-layout} {
                margin: 0 -5px;
            }
            @media #{$md-layout} {
                margin: 0 -5px;
            }
            @media #{$sm-layout} {
                margin: 0 -5px;
            }
            & > div {
                padding: 0 10px;
                @media #{$lg-layout} {
                    padding: 0 5px;
                }
                @media #{$md-layout} {
                    padding: 0 5px;
                }
                @media #{$sm-layout} {
                    padding: 0 5px;
                }
            }
        }
    }

    .rbt-header-left {
        text-align: left;
        .rbt-header-content {
            justify-content: flex-start;
        }
    }

    .rbt-header-right {
        text-align: right;
        .rbt-header-content {
            justify-content: flex-end;
        }
    }

    .rbt-header-center {
        text-align: center;
        .rbt-header-content {
            justify-content: center;
        }
    }

    &.flex-row-reverse {
        .rbt-header-left {
            text-align: right;
            .rbt-header-content {
                justify-content: flex-end;
            }
        }
        .rbt-header-right {
            text-align: left;
            .rbt-header-content {
                justify-content: flex-start;
            }
        }
    }

}

.rbt-header-wrapper {
    .rbt-header-content {
        display: flex;
        align-items: center;
        margin: 0 -5px;
        & > div {
            padding: 0 5px;
        }
    }
}


/*--------------------------
    Header Quick Access
----------------------------*/

.quick-access {
    display: flex;
    align-items: center;
    margin: 0 -3px;
    padding: 0;
    
    @media #{$lg-layout} {
        border-right: 0 none;
    }

    @media #{$md-layout} {
        border-right: 0 none;
    }

    @media #{$sm-layout} {
        border-right: 0 none;
    }

    > li {
        margin: 0 3px;
        display: flex;
        align-items: center;
        @media #{$sm-layout} {
            margin: 0 6px;
        }
        > a {
            color: var(--color-heading);
            font-size: 16px;
            font-weight: 500;
            transition: 0.4s;
            i {
                margin-right: 10px;
            }
        }

        &:hover {
            > a {
                color: var(--color-primary);
            }
        }

        &.account-access {
            padding-left: 30px;
            margin-left: 30px;
            margin-right: 30px;
            position: relative;
            @media #{$laptop-device} {
                margin-right: 20px;
                margin-left: 15px;
                padding-left: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 14px;
                margin-left: 15px;
                margin-right: 15px;
            }
            @media #{$md-layout} {
                padding-left: 10px;
                margin-left: 10px;
                margin-right: 20px;
            }

            @media #{$sm-layout} {
                padding-left: 10px;
                margin-left: 10px;
                margin-right: 5px;
            }

            @media #{$large-mobile} {
                padding-left: 0;
                margin-left: 5px;
            }
            
            &::after {
                background: var(--color-border);
                width: 1px;
                height: 30px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                content: "";
                @media #{$large-mobile} {
                    display: none;
                }
            }
        }

        &.access-icon {
            a {
                position: relative;
                z-index: 1;
                i {
                    margin-right: 0;
                }
            }
        }
    }
}





/*--------------------------
    Search With Category
----------------------------*/

.rbt-search-with-category {
    select {
        height: 100%;
        border: 0 none;
        width: 170px;
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        padding: 3px 20px;
    }
    .search-by-category {
        height: 100%;
        position: relative;
        flex: 1 1 0px;
        &::after {
            background: var(--color-border);
            position: absolute;
            content: "";
            height: 25px;
            right: 0;
            top: 50%;
            width: 1px;
            transform: translateY(-50%);
        }
    }

    .search-field {
        width: 80%;
    }
}

.rbt-search-field,
.rbt-search-with-category {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-border);
    height: 45px;
    border-radius: 500px;

    .rbt-modern-select {
        .bootstrap-select {
            button {
                &.btn-light {
                    border: 0 none;
                    box-shadow: none;
                    height: inherit;
                    padding: 10px 20px;
                    border-radius: inherit;
                    line-height: 23px;
                    padding-right: 40px;
                    background-color: transparent;
                }
            }
        }
    }
    .search-field {
        .serach-btn {
            &::after {
                display: none;
            }
        }
    }
}

.search-field {
    input {
        height: auto;
        box-shadow: none;
        padding: 0 20px;
        padding-right: 65px;
        line-height: 47px;
    }
    &.filed-solid { 
        input {
            background: var(--color-gray-light);
        }
    }
}


.search-field {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    input {
        border: 0 none;
        box-shadow: none;
    }
    .serach-btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;
        border-radius: 0;
        &::after {
            border-radius: 0;
        }
    }
}


.rbt-search-with-category {
    @media #{$sm-layout} {
        .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
            width: 139px;
        }
        .search-field input {
            padding-right: 34px;
            font-size: 13px;
            padding-left: 12px;
        }
        .rbt-modern-select .bootstrap-select button.btn-light {
            font-size: 13px;
            padding-right: 25px;
        }
        .filter-select select {
            min-width: 139px;
        }

    }
}





/*--------------------------
    Secondary Menu
----------------------------*/

.rbt-secondary-menu {
    display: flex;
    @extend %liststyle;
    margin: 0 -15px;
    @media #{$laptop-device} {
        margin: 0 -5px;
    }
    li {
        padding: 0 15px;
        margin: 0;
        @media #{$laptop-device} {
            padding: 0 5px;
        }
        a {
            font-size: 14px;
            font-weight: 500;
            position: relative;
            display: block;
            @media #{$laptop-device} {
                font-size: 13px;
            }
            &::before {
                content: "\e930";
                font-family: 'feather' !important;
                font-style: normal;
                font-weight: 400;
                margin-right: 2px;
                position: relative;
                top: 1px;
            }
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}


/*--------------------------
    Category Button
----------------------------*/

.rbt-category-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-heading);
    font-size: 16px;
    font-weight: 500;
    background: var(--black-opacity);
    padding: 10px 20px;
    border-radius: 500px;
    @media #{$lg-layout} {
        background: transparent;
        padding: 0;
    }
    @media #{$md-layout} {
        background: transparent;
        padding: 0;
    }
    @media #{$sm-layout} {
        background: transparent;
        padding: 0;
    }
    .icon {
        display: inline-block;
    }
    .category-text {
        display: inline-block;
        padding-left: 6px;
        font-size: 16px;
        line-height: 1.2;
    }
}


.rbt-category-menu-wrapper {
    position: relative;
    .category-dropdown-menu {
        min-width: 240px;
        height: auto;
        z-index: 90;
        text-align: left;
        padding: 0;
        border-radius: 0 0 10px 10px;
        background-color: var(--color-white);
        box-shadow: var(--shadow-1);
        display: flex;
        flex-direction: column;
        border-top: 1px solid #e6e3f14f;
        @extend %rbt-dropdown-type-1;
        .category-menu-item {
            display: flex;
            padding: 20px;
            .rbt-vertical-nav {
                min-width: 230px;
                width: auto;
                border-right: 1px solid #e6e3f14f;
                padding-right: 20px;
            }
            .rbt-vertical-nav-list-wrapper {
                margin: 0;
                list-style: none;
                padding: 0;
                li {
                    margin-top: 0;
                    margin-bottom: 0;
                    position: relative;
                    a {
                        font-weight: 500;
                        padding: 10px 20px;
                        font-size: 14px;
                        display: block;
                        color: var(--color-body);
                        margin: 0;
                        border-radius: 3px;
                        @extend %transition;
                    }
                    &:hover,
                    &.active {
                        a {
                            color: var(--color-primary);
                            background: var(--black-opacity);
                        }
                    }
                }
            }
            .rbt-vertical-nav-content {
                padding-left: 20px;
                height: 100%;
                width: auto;
                min-width: 320px;
                .vartical-nav-content-menu {
                    li {
                        a {
                            padding: 5px 0px;
                        }
                        &:hover,
                        &.active {
                            a {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    &:hover {
        .category-dropdown-menu {
            @extend %rbt-hover-dropdown-type-1;
        }
    }
}

.rbt-vertical-inner {
    display: none;
}


/*-------------------------
    Admin User Settings
--------------------------*/

.rbt-admin-profile {
    display: flex;
    align-items: center;
    .admin-thumbnail {
        margin-right: 12px;
        img {
            width: 52px;
            min-width: 52px;
            height: 52px;
            border-radius: 100%;
            border: 2px solid var(--primary-opacity);
            box-shadow: var(--shadow-1);
            padding: 3px;
        }
    }
    .admin-info {
        .name {
            color: var(--color-heading);
            font-weight: 500;
            display: block;
            line-height: 1;
            margin-bottom: 2px;
        }
    }
}

.rbt-user-menu-list-wrapper {
    .user-list-wrapper {
        @extend %liststyle;
        margin: 0 -12px;
        li {
            margin: 0;
            a {
                display: flex;
                padding: 5px 12px;
                align-items: center;
                @extend %transition;
                border-radius: 3px;
                color: var(--color-body);
                img,
                i {
                    max-height: 17px;
                    margin-right: 7px;
                }
                i {
                    font-size: 17px;
                }
    
                span {
                    font-size: 14px;
                }
            }
            &:hover {
                a {
                    color: var(--color-primary);
                    background: var(--black-opacity);
                }
            }
        }
    }
}


.rbt-user-wrapper {
    position: relative;
    z-index: 2;
    @media #{$large-mobile} {
        position: static;
    }
    .rbt-user-menu-list-wrapper {
        @extend %rbt-dropdown-type-1;
        background: var(--color-white);
        min-width: 250px;
        border-radius: 0 0 10px 10px;
        background-color: var(--color-white);
        box-shadow: var(--shadow-1);
        border-top: 1px solid #e6e3f14f;
        @media #{$lg-layout} {
            left: auto;
            right: 0;
        }
        @media #{$md-layout} {
            left: auto;
            right: 0;
        }
        @media #{$sm-layout} {
            left: auto;
            right: 0;
        }
        @media #{$large-mobile} {
            left: 20px;
            right: 20px;
            width: auto;
        }
        
        .inner {
            padding: 20px;
            hr {
                margin-left: -20px;
                margin-right: -20px;
            }
            .rbt-admin-profile {
                padding-bottom: 20px;
            }
        }
    }
    &:hover {
        .rbt-user-menu-list-wrapper {
            @extend %rbt-hover-dropdown-type-1;
            
        }
    }
    &.right-align-dropdown {
        .rbt-user-menu-list-wrapper {
            right: 0;
            left: auto;
            @media #{$large-mobile} {
                right: 20px;
                left: 20px;
                width: auto;
            }
        }
    }
}


/*-------------------------
    Mini Cart
--------------------------*/

.rbt-mini-cart {
    position: relative;
    
    .rbt-cart-count {
        position: absolute;
        top: 4px;
        z-index: 2;
        min-width: 16px;
        height: 16px;
        background: var(--color-primary);
        border-radius: 10px;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        color: #fff;
        padding: 0 4px 0 4px;
        line-height: 16px;
        left: 73%;
        font-weight: 500;
        @media #{$lg-layout} {
            top: 0px;
            left: 60%;
        }
        @media #{$md-layout} {
            left: auto;
            font-weight: 500;
            right: 1px;
        }
        @media #{$sm-layout} {
            top: -7px;
            right: -4px;
            left: auto;
        }
       
    }
}



/*-------------------------
    Header Animation  
--------------------------*/
@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes stickySlideDown {
    0% {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

